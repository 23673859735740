<template>
    <input
        class="px-4 py-3 text-xs border sm:text-sm border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
        :class="{ 'rounded-lg': !square }"
        :value="modelValue"
        @input="onInput"
        @focus="onFocus(field)"
        ref="input"
    />
</template>

<script>
import Icon from '@/Components/Icon.vue'

export default {
    props: {
        modelValue: {},
        square: {},
        field: {},
        form: {},
        postFocused: {},
        formBuilder: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue'],

    components: {
        Icon,
    },

    methods: {
        onInput($event) {
            if (this.form?.errors?.hasOwnProperty(this.field.name)) {
                this.form.clearErrors(this.field.name)
            }
            this.$emit('update:modelValue', $event.target.value)
        },
        onFocus(field) {
            if (this.formBuilder && this?.postFocused) {
                this.postFocused(field)
            }
        },
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>
