<template>
    <button
        :type="type"
        class="inline-flex items-center px-4 py-3 text-xs font-bold leading-4 text-white transition rounded-md focus:outline-none focus:ring focus:ring-gray-300 disabled:opacity-25"
        :class="{
            'bg-gray-400': secondary,
            'btn-bg': !secondary,
        }"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'submit',
        },
        secondary: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
.btn-bg {
    background-color: #283540;
}
</style>
