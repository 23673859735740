<template>
    <div>
        <div @click="onFocus(field)" class="p-3 bg-white border border-gray-300 border-dashed rounded-lg cursor-pointer group">
            <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2">
                    <div class="p-1 transition rounded-full bg-slate-400 group-hover:bg-[#635bff]">
                        <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-white transition">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                            />
                        </svg>
                    </div>
                    <span class="text-xs">Collect payment of {{ currencySymbol }}{{ price }}</span>
                </div>
                <svg
                    viewBox="0 0 468 222.5"
                    style="enable-background: new 0 0 468 222.5"
                    xml:space="preserve"
                    class="group-hover:fill-[#635bff] fill-slate-400 h-8 transition"
                >
                    <path
                        d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/Components/Icon.vue'

export default {
    props: {
        field: {},
        formBuilder: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['focused'],

    components: {
        Icon,
    },

    methods: {
        onFocus(field) {
            if (this.formBuilder) {
                this.$emit('focused', field)
            }
        },
    },
    computed: {
        currencySymbol() {
            if (this.field.currency === 'GBP') return '£'
            if (this.field.currency === 'EUR') return '€'
            if (this.field.currency === 'USD') return '$'
            return this.field.currency
        },
        price() {
            // return formatted price from cents
            return (this.field.price / 100).toFixed(2)
        },
    },
}
</script>
