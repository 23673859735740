export const convertHexToRGBA = (hexCode, opacity) => {
    console.log('yo', hexCode, opacity)
    let hex = hexCode.replace('#', '')

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
    }

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    const rgba = `rgba(${r},${g},${b},${opacity / 100})`
    console.log('rgba', rgba)
    return rgba
}

export const rgba2hex = (color) => {
    if (!color) return false

    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
    const hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0]) << 16) +
        (parseInt(rgba[1]) << 8) +
        parseInt(rgba[2])
    )
        .toString(16)
        .slice(1)}`

    return hex
}

export const getOpacityPercentage = (rgba) => {
    if (rgba) {
        return rgba.replace(/^rgba?\(|\s+|\)$/g, '').split(',')[3] * 100
    } else {
        return '85'
    }
}

export const doesFieldExist = (layout, fid) => {
    for (let i = 0; i < layout.length; i++) {
        const step = layout[i]
        if (step?.children) {
            if (doesFieldExist(step.children, fid)) {
                return true
            }
        } else if (step?._id === fid) {
            return true
        }
    }
    return false
}

const getFieldsFromLayoutStep = (layoutStep) => {
    const fields = []

    for (const child of layoutStep) {
        if (child.name === 'row') {
            for (const f of child.children) {
                fields.push(f)
            }
        } else if (child.name === 'stack') {
            for (const f of child.children) {
                if (f.name === 'row') {
                    for (const ff of f.children) {
                        fields.push(ff)
                    }
                } else {
                    fields.push(f)
                }
            }
        } else {
            fields.push(child)
        }
    }

    return fields
}

export const jumpToFirstErroringFormStep = (errors, layoutChildren) => {
    const erroringFieldNames = Object.keys(errors)
    const stepsAndFieldsMap = {}

    for (let i = 0; i < layoutChildren.length; i++) {
        const layoutStep = layoutChildren[i].children
        const fields = getFieldsFromLayoutStep(layoutStep)
        stepsAndFieldsMap[i] = fields.map((f) => f.name)
    }

    const order = {}

    for (let i = layoutChildren.length - 1; i >= 0; i--) {
        for (let j = 0; j < stepsAndFieldsMap[i].length; j++) {
            const fieldName = stepsAndFieldsMap[i][j]
            if (erroringFieldNames.includes(fieldName)) {
                order[i] = true
                break
            }

            order[i] = false
        }
    }

    // return the index of the first step that has an erroring field (true)
    const firstErroringStep = Object.keys(order).find((k) => {
        if (order[k] === true) return k
        return false
    })

    return parseInt(firstErroringStep) || 0
}

export const getFieldNameMap = (formFields) => {
    let fieldNames = {}
    for (const f of formFields) {
        if (f.type === 'payment') {
            fieldNames['payment'] = {
                billing_details: {
                    first_name: null,
                    last_name: null,
                    email: null,
                },
                payment_intent: null,
            }
        } else {
            fieldNames[f.name] = null
        }
    }
    return fieldNames
}
