<template>
    <div class="form-field-checkbox">
        <label :for="field.name" class="form-field-checkbox-label">
            <input type="checkbox" :name="field.name" :value="value === '' ? null : value" :id="field.name" v-model="proxyChecked" class="hidden" />
            <div class="checkbox" ref="checkbox">
                <svg viewBox="-265 388.9 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill="#fff"
                        d="M-252 427.2v-9.7c0-.9 1-1.5 1.8-1.1l13.9 9.1 22.5-19.4c.8-.4 1.8.2 1.8 1.1v9.7c0 .5-.3.9-.7 1.1l-23.6 20-15-9.7c-.4-.2-.7-.6-.7-1.1z"
                    />
                </svg>
            </div>
            <div class="form-field-checkbox-meta">
                <div class="form-field-checkbox-label" :class="{ '!mb-0': !!field?.helpBlock }">
                    {{ field.label }}
                </div>
                <p v-if="field.helpBlock && field.helpBlock.length > 0" v-text="field.helpBlock" class="font-light" />
            </div>
        </label>
    </div>
</template>

<script>
export default {
    emits: ['update:checked'],

    props: {
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        field: Object,
        value: {
            default: null,
        },
    },

    computed: {
        proxyChecked: {
            get() {
                return this.checked
            },

            set(val) {
                this.$emit('update:checked', val)
            },
        },
    },
}
</script>
