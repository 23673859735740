<template>
    <date-picker ref="datePicker" v-if="field.type !== 'date_range'" v-model="date" :input-debounce="500" @popoverWillShow="popoverWillShow" :mode="mode">
        <template v-slot="{ inputValue, inputEvents }" :is-stack="isStack">
            <div class="relative picker">
                <span class="rounded absolute w-[30px] h-[30px] top-[8px] left-3" :class="{ 'w-[18px] h-[18px] top-[4px]': isStack }">
                    <icon name="calendar" class="w-6 h-6 text-blue-500 mx-auto mt-[2px]" />
                </span>
                <input
                    class="w-full py-3 pl-12 pr-4 text-sm border outline-none border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                    :class="{ 'rounded-lg': !square, 'py-[8px] border-0 rounded-none': isStack }"
                    :value="inputValue"
                    v-on="inputEvents"
                    @focus="onFocus"
                />
            </div>
        </template>
    </date-picker>
    <date-picker ref="datePickerRange" v-else v-model="date" is-range :input-debounce="500">
        <template v-slot="{ inputValue, inputEvents }">
            <div class="grid grid-cols-2 gap-x-2 sm:gap-x-8">
                <div class="relative form-field-column">
                    <icon name="calendar" class="absolute top-0 left-0 transform translate-y-1/2 translate-x-1/2 w-6 h-6 text-[#2F75FF]" />
                    <input
                        type="text"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        placeholder="From date"
                        class="w-full py-3 pl-12 pr-4 text-sm border outline-none border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                        :class="{ 'rounded-lg': !square, 'py-[8px] border-0 rounded-none': isStack }"
                        @focus="onFocus"
                    />
                </div>
                <div class="relative form-field-column">
                    <input
                        type="text"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        class="w-full py-3 pl-12 pr-4 text-sm border outline-none border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
                        :class="{ 'rounded-lg': !square, 'py-[8px] border-0 rounded-none': isStack }"
                        placeholder="To date"
                        @focus="onFocus"
                    />
                    <icon name="calendar" class="absolute top-0 left-0 transform translate-y-1/2 translate-x-1/2 w-6 h-6 text-[#2F75FF]" />
                </div>
            </div>
        </template>
    </date-picker>
</template>
<script>
import { DatePicker } from 'v-calendar'
import Icon from '@/Components/Icon.vue'
import 'v-calendar/dist/style.css'

export default {
    props: ['field', 'square', 'form', 'isStack', 'form-builder', 'post-focused'],
    emits: ['update:modelValue'],
    components: {
        Icon,
        DatePicker,
    },

    data() {
        return {
            date:
                this.field.type === 'date_range'
                    ? {
                          start: new Date(),
                          end: new Date(),
                      }
                    : new Date(),
        }
    },

    methods: {
        popoverWillShow(data) {},
        onFocus() {
            if (this.formBuilder && this?.postFocused) {
                this.postFocused(this.field)
            }
        },
    },

    computed: {
        mode() {
            if (this.field.type === 'date_time') {
                return 'dateTime'
            }

            return 'date'
        },
    },

    watch: {
        date(value) {
            if (this.form?.errors?.hasOwnProperty(this.field.name)) {
                this.form.clearErrors(this.field.name)
            }

            this.$emit('update:modelValue', value)
        },
    },
}
</script>
