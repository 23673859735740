<template>
    <select
        class="px-4 py-3 text-sm placeholder-blue-300 border rounded-lg border-stackborder focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 text-input"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
        v-bind="$attrs"
    >
        <slot name="options"></slot>
    </select>
</template>

<script>
export default {
    props: ['modelValue'],

    emits: ['update:modelValue'],
}
</script>
