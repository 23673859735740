<template>
    <div class="form-field-radio">
        <label :for="option.value" v-for="(option, key) in field.options" :key="key" class="form-field-radio-label">
            <input type="radio" :name="field.name" :id="option.value" :value="option.value" v-model="proxyChecked" class="hidden" />
            <div class="radiobox" ref="radio">
                <div class="white-circle"></div>
            </div>
            <div class="form-field-radio-meta">
                <div class="form-field-radio-label">
                    {{ option.title }}
                </div>
                <p v-if="option.helpBlock && option.helpBlock.length > 0">{{ option.helpBlock }}</p>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    emits: ['update:checked'],

    props: {
        checked: {
            type: [Array, Boolean, String],
            default: false,
        },
        field: Object,
        value: {
            default: null,
        },
    },

    computed: {
        proxyChecked: {
            get() {
                return this.checked
            },

            set(val) {
                this.$emit('update:checked', val)
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.form-field-radio label:hover .radiobox {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 86, 0.08) 0px 3px 9px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.form-field-radio input[type='radio']:checked ~ .radiobox {
    background: #307bfc;
    height: 14px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, #2f76ef 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    min-width: 14px;
    width: 14px;
    transform: translateY(4px);
}

.form-field-radio input[type='radio']:checked ~ .radiobox {
    .white-circle {
        opacity: 1;
        transform: scale(1);
    }
}

.radiobox {
    background-color: rgb(255, 255, 255);
    height: 14px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    min-width: 14px;
    width: 14px;
    transform: translateY(4px);
    border-radius: 14px;
    position: relative;
    transition: background-color 0.24s, box-shadow 0.24s;
    box-sizing: border-box;

    .white-circle {
        opacity: 0;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin: 4px;
        background: #fff;
        box-shadow: 0 2px 2px 0 darken(#307bfc, 15%);
        transform: scale(0.2);
        transition: all 0.35s ease-in-out;
    }
}

.form-field-radio {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .form-field-radio-label {
        display: flex;
        font-size: 13px;
        cursor: pointer;
        margin-bottom: 5px;
        font-weight: 700;
        margin-top: 2px;
    }

    .form-field-radio-meta {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        p {
            margin: 0;
            font-size: 13px;
            font-weight: 400;
            color: #697386;
            white-space: normal;
            line-height: 20px;
        }
    }
}
</style>
