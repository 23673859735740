<template>
    <div>
        <div class="">
            <input ref="file" type="file" :accept="accept" class="hidden" @change="change" />

            <div
                v-if="!modelValue"
                class="flex items-center w-full p-4 transition border border-dashed rounded cursor-pointer border-stackborder hover:bg-gray-50 justify-content"
                @click="browse"
            >
                <div class="mx-auto text-center">
                    <icon class="w-12 h-12 mx-auto" name="picture" />
                    <div class="mb-4 text-[12px] font-semibold text-gray-500 sm:text-sm">Click <span class="text-blue-500">here</span> to upload your file</div>
                    <p
                        v-if="$page.props.form?.active_field_rules[field.name]?.includes('|image')"
                        class="inline p-1 px-2 mx-auto text-xs text-center text-gray-400 bg-white border rounded"
                    >
                        image files only
                    </p>
                </div>
            </div>
            <div
                v-else
                class="relative flex items-center justify-between px-4 py-5 overflow-hidden border rounded-lg border-stackborder"
                :class="{ 'pb-3': progress }"
            >
                <div class="flex items-center flex-1" :class="{ 'opacity:50': progress }">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-10 mr-2">
                        <path
                            d="M453.06 109.29a10 10 0 00-2.93-7.07L350.71 2.8a10 10 0 00-7.07-2.93H69.22a10 10 0 00-10 10v492a10 10 0 0010 10h373.84a10 10 0 0010-10z"
                            fill="#f1f1f1"
                        />
                        <path d="M452.73 106.8a9.88 9.88 0 00-2.6-4.58L350.71 2.8a10 10 0 00-7.07-2.93v94.92a12 12 0 0012 12z" fill="#ccc" />
                        <path d="M452.73 106.8h-97.08a12 12 0 01-12-12v10.15c0 6.63 4.12 15 12.38 15h97v-10.66a6.46 6.46 0 00-.3-2.49z" fill="#e8e8e8" />
                    </svg>
                    <div :class="{ 'disabled:opacity:50': progress }" class="flex flex-col">
                        <div class="text-xs font-bold text-[#283540] mb-1">{{ modelValue.name }}</div>
                        <div class="flex">
                            <span v-if="!error" class="text-xs text-gray-400">{{ filesize(modelValue.size) }}</span>
                            <p v-if="error" class="text-xs text-[#cc2d2d]" v-text="error" />
                            <span v-if="done" class="text-xs text-gray-400">&nbsp;- uploaded</span>
                        </div>
                    </div>
                    <div
                        v-if="progress"
                        :style="`width: ${progress}%; height:7px;`"
                        class="absolute bottom-0 left-0 bg-gradient-to-r to-[#76D7D2] from-blue-500"
                    ></div>
                </div>
                <button type="button" class="p-2 bg-[#668093] rounded-lg text-white" @click="remove">
                    <icon name="trash" class="w-5 h-5" />
                </button>
            </div>
        </div>
        <button
            v-if="modelValue && !done"
            type="button"
            @click.prevent="upload"
            :disabled="progress"
            class="w-full p-3 mt-2 text-xs text-white transition bg-blue-500 rounded-lg focus:ring focus:ring-gray-300 disabled:opacity-50"
        >
            Upload
        </button>
        <p v-if="field?.helpBlock" class="mt-1 text-sm" v-text="field?.helpBlock" />
    </div>
</template>

<script>
import Icon from '@/Components/Icon.vue'
import InputError from '@/Pixelform/InputError.vue'

export default {
    components: { InputError, Icon },
    emits: ['update:modelValue'],
    props: {
        modelValue: File,
        label: String,
        accept: String,
        setError: Function,
        field: Object,
        errors: {
            type: Array,
            default: () => [],
        },
        formBuilder: {
            type: Boolean,
            default: false,
        },
        postFocused: {
            type: [Function, null],
            default: null,
        },
    },
    data() {
        return {
            error: null,
            progress: null,
            done: false,
            storageLimitReached: false,
        }
    },
    watch: {
        modelValue(value) {
            if (!value) {
                this.$refs.file.value = ''
            }
        },
    },
    methods: {
        filesize(size) {
            var i = Math.floor(Math.log(size) / Math.log(1024))
            return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
        },
        browse() {
            if (this.formBuilder && this?.postFocused) {
                this.postFocused(this.field)

                return false
            }

            this.$refs.file.click()
        },
        change(e) {
          this.$emit('update:modelValue', e.target.files[0])
          this.$nextTick(() => {
            this.checkStorageLimit()
          })
          this.done = false
          this.progress = null
          this.error = null
          this.setError(this.field.name, null)
        },
        remove() {
            this.$emit('update:modelValue', null)
            this.done = false
            this.progress = null
            this.error = null
        },
        async upload() {
            // check if storage limit hasn't been reached
            await this.aws()
        },

        async checkStorageLimit() {
            try {
                const res = await axios.post(`/form/${this.$page.props.form._id}/storage-limit`, { file_size: this.modelValue.size })
                this.storageLimitReached = res.data.limit_reached
                this.error = res.data.limit_reached ? 'Storage limit reached for this form' : null
            } catch (err) {
                console.log('err', err)
            }
        },

        async aws() {
            await this.handleUrlSigning()
                .then(async (res) => {
                    this.error = null
                    await this.uploadFileToAWS(res.data.url)

                    let file = {}
                    file['extension'] = this.modelValue.name.split('.').pop()
                    file['key'] = res.data.key
                    file['uuid'] = res.data.uuid
                    file['bucket'] = res.data.bucket
                    file['size'] = this.modelValue.size
                    file['name'] = this.modelValue.name
                    file['type'] = this.modelValue.type

                    this.$emit('update:modelValue', file)
                })
                .catch(async (err) => {
                    if (err.response.status === 422) {
                        if (err.response.data.hasOwnProperty(this.field.name)) {
                            this.error = err.response.data[this.field.name][0]
                        } else {
                            this.error = 'There was an error validating your upload request. Please try again.'
                        }
                    }
                })
        },
        async handleUrlSigning() {
            let formData = new FormData()
            formData.append(this.field.name, this.modelValue)
            formData.append('formId', this.$page.props.form._id)
            formData.append('fieldName', this.field.name)

            return await axios.post(`/form/signed-storage-url`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },

        async uploadFileToAWS(url) {
            await axios.put(url, this.modelValue, {
                cancelToken: '',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    this.progress = (progressEvent.loaded / progressEvent.total) * 100
                },
            })

            this.progress = null
            this.done = true
        },
    },
}
</script>
