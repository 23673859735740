<template>
    <div class="powered-by">
        <div class="font-sans text-xs font-regular">made with</div>
        <a
            href="https://pixelform.io?ref=badge"
            target="_blank"
            class="font-sans"
            >pixelform</a
        >
    </div>
</template>
<style scoped>
.powered-by {
    color: #8c9da8;
    font-family: 'Montserrat', serif;
    font-size: 12px;
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.powered-by a {
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: #9cadb7;
    letter-spacing: -1px;
    margin-left: 5px;
    cursor: pointer;
    transition: color 0.35s ease-in-out;
}

.powered-by a:hover {
    color: #2f76ef;
}
</style>
