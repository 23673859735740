<template>
    <div @click="onFocus" v-if="formBuilder" class="group border border-dashed border-gray-300 rounded-t-xl">
        <div class="bg-white group-hover:bg-slate-50 m-1 transition rounded-t-lg flex items-center justify-center p-10">
            <icon name="pencil" class="w-5 h-5 text-slate-500 transition" />
        </div>
    </div>

    <div v-else class="relative">
        <Vue3Signature
            ref="elemRef"
            v-model="signature"
            :sigOption="state.option"
            :disabled="state.disabled"
            :onEnd="save"
            :h="'120px'"
            class="signature"
        ></Vue3Signature>
        <button type="button" class="border-l border-b border-gray-300 py-1.5 px-2 absolute top-[1px] right-[1px] rounded-tr-lg rounded-bl-lg" @click="clear">
            <icon name="undo" class="w-4 h-4" />
        </button>
    </div>
</template>

<script setup>
import Icon from '@/Components/Icon.vue'
import { reactive, ref, defineAsyncComponent } from 'vue'

const Vue3Signature = defineAsyncComponent(() => import('vue3-signature'))

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    formBuilder: {
        type: Boolean,
        required: true,
    },
    postFocused: {
        type: Function,
        required: false,
    },
    isStack: {
        type: Boolean,
        default: false,
    },
})

const state = reactive({
    count: 0,
    option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
    },
    disabled: false,
    saved: false,
})

const elemRef = ref(null)
const signature = ref(null)

const save = (t) => {
    signature.value = elemRef.value.save(t)
    state.saved = true
    emit('update:modelValue', signature.value)
}

const clear = () => {
    state.saved = false
    elemRef.value.clear()
}

const onFocus = () => {
    if (props.formBuilder) {
        props.postFocused(props.field)
    }
}
</script>
<style scoped>
.signature {
    border: 1px solid rgb(224 225 228);
    border-radius: 0.5rem;
    overflow: hidden;
}
</style>
