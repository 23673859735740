<template>
    <div :class="{ 'stack-field-column': isStack }" class="relative">
        <!-- Field label -->
        <pf-label v-if="field.type !== 'checkbox' && !isStack && !field?.children && field.type !== 'payment'">
            {{ field.label }}
        </pf-label>

        <!-- If field item has children (it's a row) then we want to loop through -->
        <div v-if="!isStack && field.name === 'row'" :class="`grid gap-5 grid-cols-${field.children.length}`">
            <pf-field-renderer
                :post-focused="postFocused"
                :form-builder="formBuilder"
                v-for="item in field.children"
                :field="item"
                :key="item.name"
                :is-stack="false"
                :square="false"
                :form="form"
            />
        </div>

        <!-- If it's a row but in a stack, loop through but with different styles applied -->
        <div v-if="isStack && field.name === 'row'" :class="`stack-field-row grid gap-0 divide-x divide-[#E0E1E4]-1 grid-cols-${field.children.length}`">
            <pf-field-renderer
                :post-focused="postFocused"
                :form-builder="formBuilder"
                v-for="item in field.children"
                :field="item"
                :key="item.name"
                :is-stack="true"
                :form="form"
            />
        </div>

        <!-- It's a stack, render the thing and display any error messages right below it -->
        <div v-if="field.name === 'stack'" class="stack">
            <pf-label>{{ field.title }}</pf-label>
            <div class="stack-grid border border-[#E0E1E4] rounded-lg grid grid-cols-1 divide-y divide-[#E0E1E4]-1 overflow-hidden">
                <pf-field-renderer
                    :post-focused="postFocused"
                    :form-builder="formBuilder"
                    v-for="item in field.children"
                    :is-stack="true"
                    :field="item"
                    :key="item.name"
                    :form="form"
                />
            </div>
            <pf-input-error v-if="Object.keys(form?.errors || {}).length > 0" :message="getStackError(field.children)" class="mt-1" />
        </div>

        <!-- Render text fields -->
        <pf-input
            v-if="shouldRenderTextField(field)"
            v-bind="$attrs"
            class="w-full"
            :form="form"
            :field="field"
            :type="field.type"
            v-model="form[field.name]"
            :placeholder="isStack ? field.label : ''"
            :post-focused="postFocused"
            :square="isStack"
            :form-builder="formBuilder"
            :class="{
                'py-[8px] border-0 rounded-none': isStack,
            }"
        />

        <!-- Payment field -->
        <pf-payment-field v-if="formBuilder && field.type === 'payment'" :field="field" :form-builder="formBuilder" @focused="postFocused" />
        <pf-payment-capture-field ref="paymentCaptureField" v-if="!formBuilder && field.type === 'payment'" :field="field" :form="form" />

        <!-- Render field for dealing with file(s) -->
        <pf-file-input
            v-if="field.type === 'file'"
            v-model="form[field.name]"
            :error="getFieldError(field.name)"
            :set-error="setError"
            :label="field.label"
            :field="field"
            :post-focused="postFocused"
            :form-builder="formBuilder"
            :class="{
                'py-[8px] border-0 rounded-none': isStack,
            }"
        />

        <!-- Render select field -->
        <pf-select
            v-else-if="field.type === 'select'"
            v-model="form[field.name]"
            :field="field"
            class="w-full"
            :post-focused="postFocused"
            :form-builder="formBuilder"
            v-bind="$attrs"
            :class="{
                'border-0 py-[8px] rounded-none': isStack,
            }"
        >
            <template #options>
                <option selected value="">Choose</option>
                <option v-for="(option, key) in field.options" :value="option.value" :key="key">
                    {{ option.title }}
                </option>
            </template>
        </pf-select>

        <!-- Render checkbox field -->
        <pf-checkbox v-else-if="field.type === 'checkbox'" v-model:checked="form[field.name]" :field="field" />

        <!-- Render radio field  -->
        <pf-radio v-else-if="field.type === 'radio'" v-model:checked="form[field.name]" :field="field" />

        <!-- Render textarea field  -->
        <pf-textarea
            v-else-if="field.type === 'textarea'"
            v-model="form[field.name]"
            :post-focused="postFocused"
            :form-builder="formBuilder"
            :is-stack="isStack"
            :field="field"
            :class="{
                'py-[8px] border-0 rounded-none': isStack,
            }"
        />

        <pf-signature
            v-else-if="field.type === 'signature'"
            v-model="form[field.name]"
            :field="field"
            :form-builder="formBuilder"
            :post-focused="postFocused"
            :is-stack="isStack"
        />

        <!-- Render datepicker field -->
        <pf-datepicker
            v-else-if="field.type === 'date' || field.type === 'date_time' || field.type === 'date_range'"
            v-model="form[field.name]"
            :post-focused="postFocused"
            :form-builder="formBuilder"
            :field="field"
            :is-stack="isStack"
            :form="form"
        />

        <p
            v-if="['text', 'email', 'select', 'date', 'textarea', 'date_time', 'date_range'].includes(field.type) && !isStack"
            v-text="field?.helpBlock"
            class="text-sm"
            :class="{ 'mb-2': field.type === 'select', 'mt-1': field.type !== 'select' }"
        />

        <!-- Lastly, render the error below the field -->
        <pf-input-error v-if="!isStack" :message="getFieldError(field.name) && getFieldError(field.name)[0]" class="mt-1" />
        <icon
            v-if="['text', 'email', 'select'].includes(field.type) && getFieldError(field.name)"
            name="error"
            class="absolute p-1 text-red-600 transition rounded-full cursor-pointer right-3 hover:bg-gray-100"
            :class="{
                'right-7': field.type === 'select',
                'w-6 h-6 top-[6px]': isStack,
                'w-6 h-6 top-[36px]': !isStack,
            }"
        >
        </icon>
    </div>
</template>
<script setup>
import PfLabel from '@/Pixelform/Label.vue'
import PfInput from '@/Pixelform/Input.vue'
import PfInputError from '@/Pixelform/InputError.vue'
import PfSelect from '@/Pixelform/Select.vue'
import PfCheckbox from '@/Pixelform/Checkbox.vue'
import PfRadio from '@/Pixelform/Radio.vue'
import PfFileInput from '@/Pixelform/FileInput.vue'
import PfTextarea from '@/Pixelform/Textarea.vue'
import PfDatepicker from '@/Pixelform/FieldDatepicker.vue'
import PfPaymentField from '@/Pixelform/PaymentField.vue'
import PfPaymentCaptureField from '@/Pixelform/PaymentCaptureField.vue'
import Icon from '@/Components/Icon.vue'
import PfFieldRenderer from '@/Pixelform/FieldRenderer.vue'
import PfSignature from '@/Pixelform/Signature.vue'

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    form: {
        type: Object,
        required: true,
    },
    isStack: {
        type: Boolean,
        required: false,
        default: false,
    },
    mode: { type: String, default: null },
    formBuilder: { type: Boolean, default: false },
    postFocused: {
        type: Function,
        default: () => {},
    },
})

const setError = (fieldName, value) => props.form.setError(fieldName, value)


const shouldRenderTextField = (field) => {
    const list = ['textarea', 'checkbox', 'radio', 'select', 'file', 'date', 'date_time', 'date_range', 'payment', 'signature']

    return !list.includes(field.type) && field.name !== 'stack' && field.name !== 'row' && field.name !== 'payment'
}
const getFieldError = (fieldName) => {
    if (props.form.errors?.hasOwnProperty(fieldName)) {
        return props.form.errors[fieldName]
    }
    return null
}

const getStackError = (fields) => {
    let keys = []

    for (const field of fields) {
        if (field.name === 'row') {
            for (const f of field.children) {
                keys.push(f.name)
            }
        } else {
            keys.push(field.name)
        }
    }
    for (const k of keys) {
        if (props.form.errors.hasOwnProperty(k)) {
            return props.form.errors[k][0]
        }
    }
}
</script>
<style lang="scss">
.stack-grid label {
    display: none;
}
</style>
