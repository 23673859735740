<template>
    <div class="fun-bg-angle">
        <div class="bg-angle" :style="`background: ${bgColor};`">
            <div class="first-stripe"></div>
            <div class="second-stripe"></div>
            <div class="third-stripe"></div>
            <div class="fourth-stripe"></div>
            <div class="fifth-stripe"></div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'
const bgColor = ref('#307afd')
</script>
