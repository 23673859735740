<template>
    <label class="block font-regular text-xs sm:text-sm text-[#394752] mb-1">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot></slot></span>
    </label>
</template>

<script>
export default {
    props: ['value'],
}
</script>
