<template>
    <textarea
        class="border border-stackborder focus:placeholder-gray-300 focus:ring-4 focus:border-blue-400 focus:ring-blue-200 focus:ring-opacity-50 rounded-lg w-full text-sm px-4 py-3"
        :value="modelValue"
        :placeholder="isStack ? field.label : ''"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="onFocus"
        ref="input"
    ></textarea>
</template>

<script>
export default {
    props: ['modelValue', 'field', 'form-builder', 'post-focused', 'isStack'],

    emits: ['update:modelValue'],

    methods: {
        onFocus() {
            if (this.formBuilder && this?.postFocused) {
                this.postFocused(this.field)
            }
        }
    }
}
</script>
